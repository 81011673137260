var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equity" },
    [
      _c("div", { staticClass: "equity-top" }, [
        _c("div", { staticClass: "equity-top-left" }, [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: _vm.version
                ? _vm.FreeSrc
                : _vm.productInfo.productVersion === "profession"
                ? _vm.ProSrc
                : _vm.ProTrySrc,
            },
          }),
          _c("div", { staticClass: "equity-surplus-items" }, [
            _c("div", [
              _c("img", {
                staticClass: "surplus-img",
                attrs: { src: _vm.surplusImg },
              }),
            ]),
            _c("div", [
              _c("div", { staticClass: "surplus-top" }, [
                _c("span", { staticClass: "surplus-title" }, [
                  _vm._v("服务期："),
                ]),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.endDateShow,
                        expression: "endDateShow",
                      },
                    ],
                    staticClass: "surplus-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.version ? "超长使用期" : _vm.productInfo.startDate
                      ) +
                        _vm._s(
                          _vm.version ? "" : " 至 " + _vm.productInfo.endDate
                        )
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "surplus-bottom" }, [
                _c("span", { staticClass: "surplus-title" }, [
                  _vm._v("剩余："),
                ]),
                _c("span", { staticClass: "surplus-text" }, [
                  _vm._v(
                    _vm._s(_vm.version ? "超长使用期" : _vm.productInfo.surplus)
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "equity-top-right" }, [
          _c("div", { staticClass: "equity-top-employees" }, [
            _c("div", [
              _c("img", {
                staticClass: "surplus-img",
                attrs: { src: _vm.employeesImg },
              }),
            ]),
            _c("div", [
              _c("div", { staticClass: "surplus-top" }, [
                _c("span", { staticClass: "surplus-title" }, [
                  _vm._v("在职员工人数："),
                ]),
                _c("span", { staticClass: "surplus-text" }, [
                  _vm._v(
                    _vm._s(_vm.productInfo.actualLicenses) +
                      _vm._s(
                        _vm.productInfo.equityLicenses
                          ? "/" + _vm.productInfo.equityLicenses
                          : ""
                      ) +
                      "人"
                  ),
                ]),
              ]),
              _vm._m(0),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "equity-items" },
        _vm._l(_vm.equityList, function (item, index) {
          return _c(
            "div",
            {
              key: "items" + index,
              staticClass: "items",
              style: { backgroundImage: "url(" + item.img + ")" },
            },
            [
              _c("div", { staticClass: "items-header" }, [
                _c("div", { staticClass: "title-imgs" }, [
                  _c("div", { staticClass: "headerImg" }, [
                    _c("img", { attrs: { src: item.headerImg } }),
                  ]),
                  _c(
                    "div",
                    { style: { color: "" + item.color, lineHeight: "24PX" } },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "items-article" },
                _vm._l(item.children, function (list, listIndex) {
                  return _c(
                    "div",
                    { key: "lists" + listIndex, staticClass: "lists" },
                    [
                      _c("div", { staticClass: "lists-img" }, [
                        _c("img", { attrs: { src: list.itemSrc } }),
                      ]),
                      _c("span", [_vm._v(_vm._s(list.text))]),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "items-button" }),
            ]
          )
        }),
        0
      ),
      _c("h3", [_vm._v("增值服务")]),
      _c(
        "div",
        { staticClass: "equity-services" },
        _vm._l(_vm.ValueAddedServices, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "equity-services-items" },
            [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _c("div", { staticClass: "separate" }),
                _c("p", { staticClass: "services-p" }, [
                  _vm._v(_vm._s(item.text)),
                ]),
                _c("div", {
                  staticClass: "service-button",
                  on: {
                    click: function ($event) {
                      return _vm.open($event, item)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("img", { attrs: { src: item.bgSrc, alt: "" } }),
              ]),
            ]
          )
        }),
        0
      ),
      _c("UpgradeAlert", { ref: "alert", on: { changeActive: _vm.change } }),
      _c("UpgradeAdministrators", { ref: "admin" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "surplus-bottom" }, [
      _c("div", { staticClass: "surplus-text personModal exp_div" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }